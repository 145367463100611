import * as React from 'react';
import { useEffect, useState, useLayoutEffect } from 'react';
import { PublicCosting } from '../../templates/PublicCosting/PublicCosting';
import { Router } from '@reach/router';
import Page from '../../templates/Page';
import { RouteComponentProps, Redirect } from '@reach/router';
import { MSALSecureRoute } from '../secure/[...].tsx.old';
import { AuthConfig, useGraphFetchClient } from '../../services/auth.service';
import { PublicClientApplication } from '@azure/msal-browser';
import { SiteGlobalConfig } from '../../site.config';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { WebBlock } from '../../components/WebBlock/WebBlock';

// import * as styles from './costing.module.scss'
import * as globalStyles from '../../globals.module.scss';

export interface ICostingProps{

}

export interface IPreviewPageWrapperProps extends RouteComponentProps{
    pageId?: string;
}

export interface IPreviewBlockWrapperProps extends RouteComponentProps{
    blockId?: string;
}

export default function Preview(){
    const msalInstance = new PublicClientApplication(AuthConfig);

    useEffect(() => {
        window.localStorage.setItem("pbovicgovau_post_login_redirect", window.location.pathname);
    }, [])
    return(
        <MsalProvider instance={ msalInstance }>
            {
                // If logged in always try to render the page
            }
            <AuthenticatedTemplate>
                <Router basepath="/preview">
                    <PreviewPageWrapper path="/page/:pageId" />
                    {/* <MSALSecureRoute component={ PreviewBlockWrapper } path="/block/:blockId" isSecure={ true } /> */}
                    <PreviewBlockWrapper path="/block/:blockId" />
                </Router>
            </AuthenticatedTemplate>
            {
                // If NOT logged in, redirect to the login page
            }
            <UnauthenticatedTemplate>
                <Redirect to="/secure/login" />
            </UnauthenticatedTemplate>
        </MsalProvider>
    )
}

export function PreviewPageWrapper(props: IPreviewPageWrapperProps){

    const { path, pageId } = props;

    useEffect(() => {
        console.log(pageId)
    },[pageId]);

    const getPageItem = (pageId: string) => {
        
    }

    // return (
    //     <Page pageContext={undefined} data={{}} />
    // )
    return (
        <p>{ pageId }</p>
    )
}

export function PreviewBlockWrapper(props: IPreviewBlockWrapperProps){
    const { blockId } = props;
    const { GRAPH_ROOT_URL, CMS_SHAREPOINT_SITE_ID, CMS_CONTENT_LIST_ID } = SiteGlobalConfig;
    const { instance, accounts } = useMsal();
    const fetchClient = useGraphFetchClient(instance);
    const [blockObject, setBlockObject] = useState<any>({});
    const [frameMessage, setFrameMessage] = useState<any>();

    useEffect(() => {
        window.addEventListener("message", (event) => handleFrameMessage(event) ,false);
    },[]);
    
    useLayoutEffect(() => {
        parent.window.postMessage({
            height: window.document.body.scrollHeight
        }, "*");
    })
    
    useEffect(() => {
        if(blockId){
            getBlock(blockId);
        }
    },[blockId]);

    const getBlock = async (blockId: string) => {
        console.log("GET BLOCK CALLED");
        const blockUrl = `${GRAPH_ROOT_URL}/sites/${CMS_SHAREPOINT_SITE_ID}/lists/${CMS_CONTENT_LIST_ID}/items/${blockId}`;
        const res = await (await fetchClient.fetch(blockUrl)).json();
        setBlockObject(res ? res.fields : {});
    }

    const handleFrameMessage = (e: any) => {
        const { data } = e;
        if(data && data.ContentType){
            setFrameMessage(data);
            setBlockObject(data);
    
            parent.window.postMessage({
                height: window.document.body.scrollHeight
            }, "*");
        }
    }

    const parseObj = () => {
        const { Id, ...restOfProps} = blockObject;

        return {
            originalId: Id,
            id: Id,
            ...restOfProps
        }
    }

    return (
        <div>
            <section className={ [globalStyles.sectionGrid, 'page-section-grid'].join(" ") } style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)", border: "dashed 1px red", margin: "1em 10%" }}>
            {
                blockObject && blockObject.ContentType && (
                    <WebBlock contentType={ blockObject.ContentType } blockProps={ blockObject } gridRow={ 1 } />
                )
            }
            {/* <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "1/2" }} />
            <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "2/3" }} />
            <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "3/4" }} />
            <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "4/5" }} />
            <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "5/6" }} />
            <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "6/7" }} />
            <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "7/8" }} />
            <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "8/9" }} />
            <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "8/10" }} />
            <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "10/11" }} />
            <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "11/12" }} />
            <div style={{ borderRight: "dashed 1px red", height: "100%", gridRow: 1, gridColumn: "12/13" }} /> */}
            </section>
        </div>
    )
}